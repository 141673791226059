<template>
  <li class="nav-item">

    <div v-if="!to" class="nav-link with-group" @click="handleExpand">
      <i :class="icon+' float-left'" v-if="icon"></i>
      <span class="text-overflow">{{title}}</span>
      <i v-if="!disabled" class="drop-group" :class="!expanded ? 'i-chevron-right' : 'i-chevron-down'"></i>
      <i v-else class="drop-group i-close-circle"></i>
    </div>

    <div v-if="to" class="nav-link with-group" :style="{ paddingLeft: offset_left }">
      <router-link :to="to" class="nav-link with-group" :style="{ paddingLeft: '0' }" ref="test">
        <i :class="icon+' float-left'" v-if="icon"></i>
        <span class="text-overflow">{{title}}</span>
      </router-link>
      <i v-if="!disabled" @click="handleExpand" class="drop-group" :class="!expanded ? 'i-chevron-right' : 'i-chevron-down'"></i>
      <i v-else @click="handleExpand" class="drop-group i-close-circle"></i>
    </div>

    <ul class="menu-nav" :class="{active: expanded}">
      <slot></slot>
    </ul>
  </li>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'i-menu'
      },
      active: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      offset_left: {
        type: String
      },
      to: [String, Object],
      title: [String, Number]
    },
    watch: {
      isActive: {
        immediate: true,
        handler(newVal) {
          if (!newVal) return;
          if (newVal === true) {
            this.expanded = true;
          }
        }
      }  
    },
    data() {
      return {
        isActive: this.active,
        expanded: false
      }
    },
    mounted () {
      if(this.$refs['test'])
        this.$refs['test'].$el.addEventListener('click', () => { this.isActive = !this.isActive; this.expanded = true; })
    },
    methods: {
      handleExpand() {
        this.expanded = !this.expanded;
      }
    }
  }
</script>

<style lang="scss" scoped>
	.text-overflow {
		float: left;
		width: 70%;
  }
</style>