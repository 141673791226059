var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item" }, [
    !_vm.to
      ? _c(
          "div",
          {
            staticClass: "nav-link with-group",
            on: { click: _vm.handleExpand }
          },
          [
            _vm.icon ? _c("i", { class: _vm.icon + " float-left" }) : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "text-overflow" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            !_vm.disabled
              ? _c("i", {
                  staticClass: "drop-group",
                  class: !_vm.expanded ? "i-chevron-right" : "i-chevron-down"
                })
              : _c("i", { staticClass: "drop-group i-close-circle" })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.to
      ? _c(
          "div",
          {
            staticClass: "nav-link with-group",
            style: { paddingLeft: _vm.offset_left }
          },
          [
            _c(
              "router-link",
              {
                ref: "test",
                staticClass: "nav-link with-group",
                style: { paddingLeft: "0" },
                attrs: { to: _vm.to }
              },
              [
                _vm.icon
                  ? _c("i", { class: _vm.icon + " float-left" })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "text-overflow" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.disabled
              ? _c("i", {
                  staticClass: "drop-group",
                  class: !_vm.expanded ? "i-chevron-right" : "i-chevron-down",
                  on: { click: _vm.handleExpand }
                })
              : _c("i", {
                  staticClass: "drop-group i-close-circle",
                  on: { click: _vm.handleExpand }
                })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "menu-nav", class: { active: _vm.expanded } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }